import * as React from "react"
import Header from '../components/header';
import Seo from '../components/seo';


const NotFoundPage = () => (
  <>
	<Seo title2="Missing page" />
	<Header siteTitle="MrJane" />
    <h2 style={{textAlign: 'center'}}>No images found here... :(</h2>
  </>
)

export default NotFoundPage
